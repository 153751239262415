<template>
  <div id="overlayer"></div>
  <div class="loader">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <header>
    <div class="container">
      <div class="row">
        <div class="col-xl-4">
          <div class="logo">
            <router-link :to="{ name: 'home' }">
              <div class="logo-icon">
                <img src="/img/logo.svg" />
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="menu-lang">
            <div class="menu">
              <nav class="navbar navbar-expand-xxl">
                <div class="container-fluid">
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul class="navbar-nav">
                      <li :class="[item.submenus.length == 0 ? 'nav-item' : 'nav-item dropdown' ]" v-for="item in menus" :key="item.id">
                        <a
                          class="nav-link"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          v-if="item.submenus.length > 0"
                        >
                          <span>{{ item.title }}</span>
                        </a>
                        <router-link v-if="item.submenus.length == 0 && item.link_type == 2" class="nav-link" :to=" '/' + lang + item.link">{{ item.title }}</router-link>
                        <router-link v-if="item.submenus.length == 0 && item.link_type == 3" class="nav-link" :to=" '/' + lang + item.link" target="_blank">{{ item.title }}</router-link>
                        <router-link v-if="item.submenus.length == 0 && item.link_type == 4" class="nav-link" :to=" '/' + lang + item.link">{{ item.title }}</router-link>
                        <router-link v-if="item.submenus.length == 0 && item.link_type == 5" class="nav-link" :to=" '/' + lang + item.link" target="_blank">{{ item.title }}</router-link>
                        <treemenu v-if="item.submenus.length > 0" :datatree="item.submenus"></treemenu>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            <div class="lang">
              <div class="dropdown">
                <a
                  class="lang-link"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>{{ lang }}</span>
                </a>
                <ul class="dropdown-menu text-center">
                  <li>
                    <button @click="switch_lang('uz')" class="dropdown-item">
                      <b>Uz</b>
                    </button>
                  </li>
                  <li>
                    <button @click="switch_lang('oz')" class="dropdown-item">
                      <b>O'z</b>
                    </button>
                  </li>
                  <li>
                    <button @click="switch_lang('ru')" class="dropdown-item">
                      <b>Ru</b>
                    </button>
                  </li>
                  <!-- <li>
                    <button @click="switch_lang('en')" class="dropdown-item">En</button>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from "axios";
import i18n from "../i18n";
import treemenu from './treemenu.vue';

export default {
  name: "myheader",
  components: {
    treemenu,
  },
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
      lang: i18n.global.locale,
      menus: null,
      protocol: location.protocol,
      host: location.host,
      path: this.$route.path.substr(4),
    };
  },
  methods: {
    main_function() {
      let width = $(document).width();
      if (width >= 1400) {
        $(".menu .dropdown").mouseenter(function (argument) {
          $(this).children(".dropdown-menu").addClass("show");
        });
        $(".menu .dropdown").mouseleave(function (argument) {
          $(this).children(".dropdown-menu").removeClass("show");
        });
      }
      $(".loader").delay(1000).fadeOut("slow");
      $("#overlayer").delay(1000).fadeOut("slow");
    },
    get_menu() {
      axios
        .get(this.api_url + "api/menus?lang=" + this.lang)
        .then((response) => {
          this.menus = response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    switch_lang(lang) {
      window.location.href = `${this.protocol}//${this.host}/${lang}/${this.path}`;
    },
  },
  computed: {},
  mounted() {
    this.main_function();
    this.get_menu();
    document.title = "Information Technology Village"
  },
};
</script>

<style scoped>
#overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7100;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.loader {
  position: fixed;
  z-index: 7102;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
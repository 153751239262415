<template>
  <section class="location">
    <div class="location-in">
      <div class="container">
        <div class="location-out">
          <div class="location-title">
            <span>{{ $t("address") }}</span>
          </div>
          <div class="location-description">
            <span>{{ $t("address_text") }}</span>
          </div>
          <div class="location-link">
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="44.325"
                height="50.432"
                viewBox="0 0 44.325 50.432"
              >
                <g transform="translate(-31)">
                  <g transform="translate(31)">
                    <path
                      d="M61.582,33.321c5.367-8.421,4.692-7.37,4.847-7.59a16.131,16.131,0,0,0,2.987-9.38,16.253,16.253,0,1,0-29.433,9.5l4.761,7.47C39.653,34.1,31,36.435,31,41.567c0,1.871,1.221,4.537,7.039,6.615a46.635,46.635,0,0,0,15.124,2.25c10.641,0,22.163-3,22.163-8.865C75.325,36.434,66.682,34.105,61.582,33.321Zm-19.131-9.1q-.024-.038-.051-.075A13.3,13.3,0,1,1,64.033,24c-.142.188.6-.966-10.87,17.033ZM53.163,47.477c-11.622,0-19.208-3.416-19.208-5.91,0-1.676,3.9-4.432,12.534-5.506l5.428,8.517a1.477,1.477,0,0,0,2.492,0l5.427-8.517c8.637,1.074,12.534,3.83,12.534,5.506C72.37,44.04,64.853,47.477,53.163,47.477Z"
                      transform="translate(-31)"
                      fill="#fff"
                    />
                  </g>
                  <g transform="translate(45.775 8.964)">
                    <path
                      d="M188.388,91a7.388,7.388,0,1,0,7.388,7.388A7.4,7.4,0,0,0,188.388,91Zm0,11.82a4.433,4.433,0,1,1,4.433-4.433A4.438,4.438,0,0,1,188.388,102.82Z"
                      transform="translate(-181 -91)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
              <span>{{ $t("seen_map") }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">{{ $t("location") }}</h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="location-yandex">
              <div id="map" class="map" ref="map"></div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-warning"
              data-bs-dismiss="modal"
            >
              {{ $t("close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "location",
  components: {},
  data() {
    return {};
  },
  methods: {
    get_element() {
      let map_element = this.$refs["map"];
      let script = document.createElement("script");
      script.src = `https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A59be6d7cf6fed0d369735ff8e8a4501429c7acc1ee95a2bc64d6d96619e6854a&amp;width=100%25&amp;height=500&amp;lang=ru_RU&amp;scroll=true`;
      map_element.append(script);
    },
  },
  computed: {},
  mounted() {
    this.get_element();
  },
};
</script>
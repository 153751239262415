<template>
  <main class="page">
    <myheader></myheader>
    <section class="detail">
      <div class="container">
        <div class="row">
            <div class="col-xl-6">
                <div class="detail-left">
                  <div class="detail-title">
                    <span>{{ detail.list_title }}</span>
                  </div>
                  <div class="detail-content">
                    <div v-html="detail.list_content"></div>   
                  </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="detail-right">
                    <div class="owl-detail">
                      <div class="owl-carousel owl-theme">
                        <div class="item">
                          <div class="detail-img">
                            <img :src="[ detail.anons_image == null ? '' : api_url + detail.anons_image ]" alt="alter">
                          </div>
                        </div>
                        <div class="item" v-for="(tag, index) in split(list_image)" :key="index">
                          <div class="detail-img">
                            <img :src="api_url + tag" alt="alter">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="detail-description">
                        <div v-html="detail.list_description"></div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
    <myfooter></myfooter>
  </main>
</template>

<script>
import i18n from "../i18n";
import myheader from "@/components/myheader.vue";
import myfooter from "@/components/myfooter.vue";
import axios from "axios";

export default {
  name: "detail",
  components: {
    myheader,
    myfooter,
  },
  data() {
    return {
      slug: this.$route.params.slug,
      api_url: process.env.VUE_APP_API_URL,
      lang: i18n.global.locale,
      detail: new Object(),
      list_image: new String,
    };
  },
  methods: {
    main_owl() {
        $('.owl-detail .owl-carousel').owlCarousel({
          loop:true,
          margin:0,
          nav:false,
          dots:true,
          autoplay:true,
          autoHeight:true,
          responsive:{
            0:{
              items:1
            },
            600:{
              items:1
            },
            1000:{
              items:1
            }
          }
        })
    },
    get_detail() {
      axios
        .get(this.api_url + "api/news/" + this.slug + "/?lang=" + this.lang)
        .then((response) => {
          this.detail = response.data.data;
          this.list_image = response.data.data.body_image;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.main_owl();
        });
    },
    split(text) {
      return text.split(',');
    },
  },
  computed: {
    ab() {
      return this.list_image == null ? '' : this.list_image;
    },
  },
  mounted() {
    this.get_detail();
  },
};
</script>
<template>
  <ul class="dropdown-menu">
    <li v-for="item in datatree" :key="item.id">
      <router-link v-if="item.submenus.length == 0 && item.link_type == 2" class="nav-link" :to=" '/' + lang + item.link">{{ item.title }}</router-link>
      <router-link v-if="item.submenus.length == 0 && item.link_type == 3" class="nav-link" :to=" '/' + lang + item.link" target="_blank">{{ item.title }}</router-link>
      <router-link v-if="item.submenus.length == 0 && item.link_type == 4" class="nav-link" :to=" '/' + lang + item.link">{{ item.title }}</router-link>
      <router-link v-if="item.submenus.length == 0 && item.link_type == 5" class="nav-link" :to=" '/' + lang + item.link" target="_blank">{{ item.title }}</router-link>
      <treemenu v-if="item.submenus.length > 0" :datatree="item.submenus"></treemenu>
    </li>
  </ul>
</template>

<script>
import i18n from '@/i18n';

export default {
  name: "treemenu",
  props: ["datatree"],
  components: {},
  data() {
    return {
      lang: i18n.global.locale,
    };
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>
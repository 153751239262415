<template>
  <main class="page">
    <myheader></myheader>
    <section class="deatil pb-5">
      <div class="container">
        <div class="heading">
          <div class="heading-in">
            <span>{{ about.category_title }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="about-img">
              <img :src="[ about.anons_image == null ? '/img/news-1.jpg' : api_url + about.anons_image ]" alt="alter">
            </div>
          </div>
          <div class="col-xl-6">
            <div class="about-description">
              <div v-html="about.list_description"></div>
            </div>
          </div>
        </div>
        <div class="about-content">
          <div v-html="about.list_content"></div>
        </div>
      </div>
    </section>
    <location></location>
    <myfooter></myfooter>
  </main>
</template>

<script>
import myheader from "@/components/myheader.vue";
import myfooter from "@/components/myfooter.vue";
import i18n from "@/i18n";
import axios from "axios";
import location from '@/components/location.vue';

export default {
  name: "AboutView",
  components: { myheader, myfooter, location },
  data() {
    return {
      lang: i18n.global.locale,
      api_url: process.env.VUE_APP_API_URL,
      about: new Object(),
    };
  },
  methods: {
    get_about() {
      axios
        .get(this.api_url + "api/pages/kompleks-haqida?lang=" + this.lang)
        .then((response) => {
          // console.log(response.data.data);
          this.about = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {},
  mounted() {
    this.get_about();
  },
};
</script>

<template>
  <main class="home">
    <section class="banner">
      <div class="banner-image">
        <div class="banner-image-in">
          <div class="banner-image-out">
            <img src="/img/banner.jpg" />
          </div>
        </div>
      </div>
      <div class="banner-before">
        <div class="banner-before-in"></div>
      </div>
      <div class="banner-in">
        <myheader></myheader>
        <div class="container">
          <div class="row">
            <div class="col-xl-4">
              <div class="banner-video">
                <div class="banner-video-in" v-if="banners.media_type === 5">
                  <a
                    data-fancybox="video-gallery"
                    :href="'https://youtu.be/' + banners.video_code"
                  >
                    <div class="banner-video-out">
                      <video
                        src="/img/banner.mp4"
                        loop=""
                        autoplay=""
                        muted=""
                      ></video>
                      <div class="banner-video-text">
                        <div>
                          <img src="/img/play.svg" alt="play" />
                        </div>
                        <div>
                          <span>{{ $t("play_video") }}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="banner-video-in" v-if="banners.media_type === 3">
                  <a
                    data-fancybox="video-gallery"
                    :href="api_url + banners.video"
                  >
                    <div class="banner-video-out">
                      <video
                        src="/img/banner.mp4"
                        loop=""
                        autoplay=""
                        muted=""
                      ></video>
                      <div class="banner-video-text">
                        <div>
                          <img src="/img/play.svg" alt="play" />
                        </div>
                        <div>
                          <span>{{ $t("play_video") }}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="banner-video-in" v-if="banners.media_type === 4">
                  <a
                    :href="'https://utube.uz/embed/' + banners.video_code"
                    data-fancybox
                    data-type="iframe"
                    data-preload="false"
                  >
                    <div class="banner-video-out">
                      <video
                        src="/img/banner.mp4"
                        loop=""
                        autoplay=""
                        muted=""
                      ></video>
                      <div class="banner-video-text">
                        <div>
                          <img src="/img/play.svg" alt="play" />
                        </div>
                        <div>
                          <span>{{ $t("play_video") }}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="banner-link">
                <div class="row align-items-center">
                  <!-- <div class="col-sm-6">
                    <div class="banner-link-in">
                      <a href="https://mitc.uz/uz">
                        <img src="/img/mitc.svg" />
                      </a>
                    </div>
                  </div> -->
                  <div class="col-sm-6">
                    <div class="banner-link-in">
                      <a href="https://uzinfocom.uz/uz/">
                        <img src="/img/uzinfocom.svg" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-8 banner-right">
              <div class="banner-text">
                <div class="banner-title">
                  <span v-html="banners.list_title"></span>
                </div>
                <div class="banner-description">
                  <span v-html="banners.list_description"></span>
                </div>
                <div class="banner-address">
                  <span>{{ $t("address") }}: {{ $t("address_text") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="service">
        <div class="container">
          <div class="heading">
            <div class="heading-in" v-html="$t('it_village')"></div>
          </div>
          <div class="service-row">
            <div class="row justify-content-center">
              <div
                v-for="item in it_village"
                :key="item"
                class="col-xl-4 col-md-6"
              >
                <div class="service-in">
                  <div class="service-icon">
                    <img
                      :src="[
                        item.anons_image
                          ? api_url + item.anons_image
                          : '/img/ser-1.svg',
                      ]"
                    />
                  </div>
                  <div class="service-text">
                    <div class="service-title">
                      <span>{{ item.list_title }}</span>
                    </div>
                    <div class="service-description">
                      <span v-html="item.list_description"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="projects">
      <div class="projects-in">
        <div class="container">
          <div class="heading">
            <div class="heading-in projects-heading">
              <span>{{ $t("project") }}</span>
            </div>
          </div>
          <div class="projects-row">
            <div class="row justify-content-center">
              <div
                v-for="item in project"
                :key="item.id"
                class="col-xxl-24 col-xl-4 col-md-6 mt-5 p-col"
              >
                <div class="projects-out">
                  <div class="projects-icon">
                    <img
                      :src="[
                        item.anons_image !== null
                          ? api_url + item.anons_image
                          : '/img/pro-1.svg',
                      ]"
                    />
                  </div>
                  <div class="projects-span">
                    <span v-html="item.list_title"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="next-in">
        <div class="container">
          <div class="heading">
            <div class="heading-in projects-heading">
              <span>{{ $t("other") }}</span>
            </div>
          </div>
          <div class="next-row">
            <div class="row justify-content-center">
              <div
                v-for="item in other"
                :key="item.id"
                class="col-xxl-24 col-xl-4 col-md-5 col-8"
              >
                <div class="next-out">
                  <div class="next-icon">
                    <img
                      :src="[
                        item.anons_image == null
                          ? '/img/next-1.svg'
                          : api_url + item.anons_image,
                      ]"
                    />
                  </div>
                  <div class="next-text">
                    <span v-html="item.list_title"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="number">
      <div class="container">
        <div class="heading">
          <div class="heading-in">
            <span>{{ $t("number") }}</span>
          </div>
        </div>
        <div class="number-row">
          <div class="row justify-content-center">
            <div
              v-for="item in number"
              :key="item.id"
              class="col-xxl-2 col-xl-3 col-md-6"
            >
              <div class="number-in">
                <div class="number-number">
                  <span>{{ item.count }}</span>
                </div>
                <div class="number-text">
                  <span v-html="item.title"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-news">
      <div class="container">
        <div class="heading">
          <div class="heading-in">
            <span>{{ $t("news") }}</span>
          </div>
        </div>
        <div class="news-row">
          <div class="row justify-content-center">
            <div
              v-for="item in news"
              :key="item.id"
              class="col-xxl-3 col-xl-4 col-md-6"
            >
              <div class="news">
                <router-link
                  :to="{ name: 'detail', params: { slug: item.slug } }"
                >
                  <div class="news-in">
                    <div class="news-img">
                      <img :src="api_url + item.anons_image" />
                    </div>
                    <div class="news-card">
                      <div class="news-title">
                        <span>{{ item.list_title }}</span>
                      </div>
                      <div class="news-description">
                        <span>{{ item.list_description }}</span>
                      </div>
                      <div class="news-date-right">
                        <span class="news-date">{{ item.date }}</span>
                        <img src="/img/news-right.svg" />
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="gallery">
      <div class="container">
        <div class="heading">
          <div class="heading-in">
            <span>{{ $t("foto") }}</span>
          </div>
        </div>
        <div class="gallery-row">
          <div class="row">
            <div class="col-xxl-3 col-xl-4 col-md-6">
              <div class="gallery-in">
                <a data-fancybox="gallery" :data-src="foto_1">
                  <div class="gallery-in mt-3">
                    <img :src="foto_1" />
                  </div>
                </a>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-4 col-md-6">
              <div class="gallery-in">
                <a data-fancybox="gallery" :data-src="foto_2">
                  <div class="gallery-in mt-3">
                    <img :src="foto_2" />
                  </div>
                </a>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-4 col-md-6">
              <div class="gallery-in">
                <a data-fancybox="gallery" :data-src="foto_3">
                  <div class="gallery-in mt-3">
                    <img :src="foto_3" />
                  </div>
                </a>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-4 col-md-6">
              <div class="gallery-in">
                <a data-fancybox="gallery" :data-src="foto_4">
                  <div class="gallery-in mt-3">
                    <img :src="foto_4" />
                  </div>
                </a>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-4 col-md-6">
              <div class="gallery-in">
                <a data-fancybox="gallery" :data-src="foto_5">
                  <div class="gallery-in mt-3">
                    <img :src="foto_5" />
                  </div>
                </a>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-4 col-md-6">
              <div class="gallery-in">
                <a data-fancybox="gallery" :data-src="foto_6">
                  <div class="gallery-in mt-3">
                    <img :src="foto_6" />
                  </div>
                </a>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-4 col-md-6">
              <div class="gallery-in">
                <a data-fancybox="gallery" :data-src="foto_7">
                  <div class="gallery-in mt-3">
                    <img :src="foto_7" />
                  </div>
                </a>
              </div>
            </div>
            <div class="col-xxl-3 col-xl-4 col-md-6">
              <div class="gallery-in">
                <a data-fancybox="gallery" :data-src="foto_8">
                  <div class="gallery-in mt-3">
                    <img :src="foto_8" />
                  </div>
                </a>
              </div>
            </div>

            <!-- <div class="col-xl-7">
              <div class="row">
                <div class="col-6 pe-1">
                  <div class="gallery-in">
                    <a data-fancybox="gallery" :data-src="foto_1">
                      <div class="gallery-1">
                        <img :src="foto_1" />
                      </div>
                    </a>
                  </div>
                  <div class="gallery-in">
                    <a data-fancybox="gallery" :data-src="foto_2">
                      <div class="gallery-1">
                        <img :src="foto_2" />
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-6 ps-1 pe-xl-1">
                  <div class="gallery-in">
                    <a data-fancybox="gallery" :data-src="foto_3">
                      <div class="gallery-2">
                        <img :src="foto_3" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-xl-3 col-8 ps-xl-1 pe-1">
              <div class="gallery-in">
                <a data-fancybox="gallery" :data-src="foto_4">
                  <div class="gallery-3">
                    <img :src="foto_4" />
                  </div>
                </a>
              </div>
              <div class="gallery-in">
                <a data-fancybox="gallery" :data-src="foto_5">
                  <div class="gallery-3">
                    <img :src="foto_5" />
                  </div>
                </a>
              </div>
              <div class="gallery-in">
                <a data-fancybox="gallery" :data-src="foto_6">
                  <div class="gallery-3">
                    <img :src="foto_6" />
                  </div>
                </a>
              </div>
            </div> -->

            <!-- <div class="col-xl-2 col-4 ps-1">
              <div class="gallery-in">
                <a data-fancybox="gallery" :data-src="foto_7">
                  <div class="gallery-4">
                    <img :src="foto_7" />
                  </div>
                </a>
              </div>
              <div class="gallery-in">
                <a data-fancybox="gallery" :data-src="foto_8">
                  <div class="gallery-4">
                    <img :src="foto_8" />
                  </div>
                </a>
              </div>
            </div> -->

          </div>
        </div>
        <div class="all-link">
          <router-link :to="{ name: 'fotos' }">{{ $t("foto") }}</router-link>
          <router-link :to="{ name: 'videos' }">{{ $t("video") }}</router-link>
        </div>
      </div>
    </section>
    <section class="partner">
      <div class="container">
        <div class="heading">
          <div class="heading-in">
            <span>{{ $t("partner") }}</span>
          </div>
        </div>
        <div class="partner-row">
          <div class="row justify-content-center align-items-center">
            <div
              v-for="item in useful"
              :key="item.id"
              class="col-xxl-3 col-xl-4 col-md-6"
            >
              <div class="partner-out">
                <a :href="item.link" target="_blank">
                  <div class="partner-in">
                    <img
                      :src="[
                        item.anons_image == null
                          ? '/img/part-2.png'
                          : api_url + item.anons_image,
                      ]"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="lesson">
      <div class="lesson-in">
        <div class="container">
          <div class="row">
            <div class="col-xxl-5 col-xl-6 col-lg-8">
              <div class="lesson-out">
                <div class="lesson-title" v-html="$t('sale')"></div>
                <div class="lesson-link">
                  <a href="#">{{ $t("stat") }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <location></location>
    <myfooter></myfooter>
  </main>
</template>

<script>
import i18n from "../i18n";
import axios from "axios";
import myheader from "@/components/myheader.vue";
import myfooter from "@/components/myfooter.vue";
import location from '@/components/location.vue';

export default {
  name: "HomeView",
  components: {
    myheader,
    myfooter,
    location,
  },
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
      lang: i18n.global.locale,
      news: new Array(),
      gallery: new Array(),
      it_village: new Array(),
      project: new Array(),
      other: new Array(),
      useful: new Array(),
      banners: new Object(),
      number: new Array(),
    };
  },
  methods: {
    get_it_village() {
      axios
        .get(this.api_url + "api/about-it-village?lang=" + this.lang)
        .then((response) => {
          // console.log(response.data.data);
          this.it_village = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_project() {
      axios
        .get(this.api_url + "api/project-content?lang=" + this.lang)
        .then((response) => {
          // console.log(response.data.data);
          this.project = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_other() {
      axios
        .get(this.api_url + "api/besides?lang=" + this.lang)
        .then((response) => {
          // console.log(response.data.data);
          this.other = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_number() {
      axios
        .get(this.api_url + "api/stats?lang=" + this.lang)
        .then((response) => {
          // console.log(response.data.data);
          this.number = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_news() {
      axios
        .get(this.api_url + "api/news-component?lang=" + this.lang)
        .then((response) => {
          // console.log(response.data.data);
          this.news = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_gallery() {
      axios
        .get(this.api_url + "api/media-component?lang=" + this.lang)
        .then((response) => {
          // console.log(response.data.data);
          this.gallery = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_useful() {
      axios
        .get(this.api_url + "api/usefuls?lang=" + this.lang)
        .then((response) => {
          // console.log(response.data.data);
          this.useful = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_banners() {
      axios
        .get(this.api_url + "api/banners?lang=" + this.lang)
        .then((response) => {
          // console.log(response.data.data);
          this.banners = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    foto_1() {
      return this.gallery[0] == null
        ? "/img/foto-1.jpg"
        : this.api_url + this.gallery[0].anons_image;
    },
    foto_2() {
      return this.gallery[1] == null
        ? "/img/foto-2.jpg"
        : this.api_url + this.gallery[1].anons_image;
    },
    foto_3() {
      return this.gallery[2] == null
        ? "/img/foto-3.jpg"
        : this.api_url + this.gallery[2].anons_image;
    },
    foto_4() {
      return this.gallery[3] == null
        ? "/img/foto-4.jpg"
        : this.api_url + this.gallery[3].anons_image;
    },
    foto_5() {
      return this.gallery[4] == null
        ? "/img/foto-5.jpg"
        : this.api_url + this.gallery[4].anons_image;
    },
    foto_6() {
      return this.gallery[5] == null
        ? "/img/foto-6.jpg"
        : this.api_url + this.gallery[5].anons_image;
    },
    foto_7() {
      return this.gallery[6] == null
        ? "/img/foto-7.jpg"
        : this.api_url + this.gallery[6].anons_image;
    },
    foto_8() {
      return this.gallery[7] == null
        ? "/img/foto-8.jpg"
        : this.api_url + this.gallery[7].anons_image;
    },
  },
  mounted() {
    this.get_news();
    this.get_gallery();
    this.get_it_village();
    this.get_project();
    this.get_other();
    this.get_useful();
    this.get_banners();
    this.get_number();
  },
};
</script>

<template>
  <main class="page">
    <myheader></myheader>
    <section class="pb-5">
      <div class="container">
        <div class="heading">
          <div class="heading-in">
            <span>{{ $t("foto") }}</span>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-xl-3 col-lg-4 col-6" v-for="(item, index) in fotos" :key="index">
            <div class="foto-out">
              <a :data-fancybox="`gallery-${index}`" :data-src="api_url + item.anons_image">
                <div class="foto-in foto-icon">
                  <img :src="api_url + item.anons_image" />
                </div>
              </a>
            </div>
            <template v-if="item.body_image !== null">
              <a :data-fancybox="`gallery-${index}`" class="d-none" :data-src="api_url + tag" v-for="(tag, inx) in split(item.body_image)" :key="inx">
                <img :src="api_url + tag"/>
              </a>
            </template>
          </div>
        </div>
        <div class="my-pagination">
          <nav aria-label="Page navigation example">
            <ul class="pagination">

              <li class="page-item">
                <button class="page-link" @click="get_fotos(1)">
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>

              <li class="page-item">
                <button class="page-link" :disabled="pagination.current_page == 1" @click="get_fotos(pagination.current_page - 1)">
                  <span aria-hidden="true">&lt;</span>
                </button>
              </li>

              <li class="page-item" v-if="pagination.current_page > 3">
                <button class="page-link" @click="get_fotos(pagination.current_page - 3)">{{ pagination.current_page - 3 }}</button>
              </li>

              <li class="page-item" v-if="pagination.current_page > 2">
                <button class="page-link" @click="get_fotos(pagination.current_page - 2)">{{ pagination.current_page - 2 }}</button>
              </li>

              <li class="page-item" v-if="pagination.current_page > 1">
                <button class="page-link" @click="get_fotos(pagination.current_page - 1)">{{ pagination.current_page - 1 }}</button>
              </li>
              
              <li class="page-item">
                <button class="page-link active" @click="get_fotos(pagination.current_page)">{{ pagination.current_page }}</button>
              </li>
              
              <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                <button class="page-link" @click="get_fotos(pagination.current_page + 1)">{{ pagination.current_page + 1}}</button>
              </li>

              <li class="page-item" v-if="pagination.current_page + 1 < pagination.last_page">
                <button class="page-link" @click="get_fotos(pagination.current_page + 2)">{{ pagination.current_page + 2}}</button>
              </li>

              <li class="page-item" v-if="pagination.current_page + 2 < pagination.last_page">
                <button class="page-link" @click="get_fotos(pagination.current_page + 3)">{{ pagination.current_page + 3}}</button>
              </li>

              <li class="page-item">
                <button class="page-link" :disabled="pagination.current_page == pagination.last_page" @click="get_fotos(pagination.current_page + 1)">
                  <span aria-hidden="true">&gt;</span>
                </button>
              </li>
              
              <li class="page-item">
                <button class="page-link" @click="get_fotos(pagination.last_page)">
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>

            </ul>
          </nav>
        </div>
      </div>
    </section>
    <myfooter></myfooter>
  </main>
</template>

<script>
import myheader from "@/components/myheader.vue";
import myfooter from "@/components/myfooter.vue";
import i18n from '@/i18n';
import axios from 'axios';

export default {
  name: "fotos",
  components: {
    myheader,
    myfooter,
  },
  data() {
    return {
        lang: i18n.global.locale,
        api_url: process.env.VUE_APP_API_URL,
        fotos: new Array,
        paginate: 12,
        pagination: new Object,
    };
  },
  methods: {
    get_fotos(per_page = 1) {
      axios
        .get(this.api_url + "api/category/fotogalereya?lang=" + this.lang + "&paginate=" + this.paginate + "&page=" + per_page)
        .then((response) => {
          this.fotos = response.data.data;
          this.pagination = response.data.meta;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    split(text) {
        return text.split(",");
    },
  },
  computed: {},
  mounted() {
    this.get_fotos();
  },
};
</script>
<template>
  <footer>
    <div class="footer-in">
      <div class="container">
        <div class="row">
          <div class="col-xl-4">
            <div class="f-logo">
              <router-link :to="{ name: 'home' }">
                <img src="/img/f-logo.svg" />
              </router-link>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="f-data">
              <span>Тел:</span>
              <a href="#">{{ contact.phone }}</a>
            </div>
            <div class="f-data">
              <span>{{ contact.email }}</span>
            </div>
            <div class="f-data">
              <span>{{ $t("address") }}: {{ contact.address }}</span>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="f-data">
              <span
                >{{ $t("site_link") }}</span
              >
            </div>
            <div class="f-data">
              <span>{{ $t("copy") }}</span>
            </div>
            <div class="f-data">
              <span>Разработка сайта:</span>
              <span>&nbsp;</span>
              <a href="https://uzinfocom.uz">
                <img src="/img/f-uzinfocom.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="footer-out">
        <span
          >{{ $t("ctrl") }}</span
        >
      </div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios';
import i18n from '../i18n';

export default {
  name: "myfooter",
  components: {},
  data() {
    return {
      contact: Object,
      api_url: process.env.VUE_APP_API_URL,
      lang: i18n.global.locale,
    };
  },
  methods: {
    get_contact() {
      axios
        .get(this.api_url + "api/contact?lang=" + this.lang)
        .then((response) => {
          // console.log(response.data.data);
          this.contact = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {},
  mounted() {
    this.get_contact();
  },
};
</script>
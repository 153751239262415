export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ru hello i18n !!"])},
  "play_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотреть видео"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "address_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самаркандская область, Нурабадский район"])},
  "it_village": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='heading-first'>Что такое </span> <span>IT-Village?</span>"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контент проекта"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кроме этого"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цифры"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости"])},
  "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фотогалерея"])},
  "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши партнёры"])},
  "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='bold'>Бесплатное обучение по IT-направлениям</span> <span class='simple'>для жителей Нуробадского района</span>"])},
  "stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стать участником"])},
  "seen_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть на карте"])},
  "site_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При использовании материалов ссылка itvillage.uz обязательна."])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022© itvillage.uz - Все права защищены"])},
  "ctrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание! Если Вы нашли ошибку в тексте, выделите её и нажмите Ctrl+Enter для уведомления администрации"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Геопозиция"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видеогалерея"])}
}
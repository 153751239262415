export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En hello i18n !!"])},
  "play_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch the video"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "address_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samarkand region, Nurabad district"])},
  "it_village": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='heading-first'>What is</span> <span>IT Village?</span>"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project content"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besides"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbers"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photogallery"])},
  "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our partners"])},
  "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='bold'>Free training in IT areas </span><span class='simple'>for residents of Nurobod district</span>"])},
  "stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a Member"])},
  "seen_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View on the map"])},
  "site_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When using materials, the link itvillage.uz is required."])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022© itvillage.uz - All rights reserved"])},
  "ctrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! If you find an error in the text, select it and press Ctrl + Enter to notify the administration"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videogalereya"])}
}
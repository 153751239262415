<template>
  <main class="page">
    <myheader></myheader>
    <section class="pb-5">
      <div class="container">
        <div class="heading">
          <div class="heading-in">
            <span>{{ $t("video") }}</span>
          </div>
        </div>
        <div class="row justify-content-center">
          <div
            class="col-xl-3 col-lg-4 col-6"
            v-for="item in video"
            :key="item.id"
          >

            <div class="foto-out" v-if="item.media_type === 5">
              <a data-fancybox="video-gallery" :href="'https://youtu.be/' + item.video_code">
                <div class="foto-in video-icon">
                  <img :src="api_url + item.image"/>
                </div>
              </a>
            </div>

            <div class="foto-out" v-if="item.media_type === 3">
              <a data-fancybox="video-gallery" :href="api_url + item.video">
                <div class="foto-in video-icon">
                  <img :src="api_url + item.image"/>
                </div>
              </a>
            </div>

            <div class="foto-out" v-if="item.media_type === 4">
              <a :href="'https://utube.uz/embed/' + item.video_code" data-fancybox data-type="iframe" data-preload="false">
                <div class="foto-in video-icon">
                  <img :src="api_url + item.image"/>
                </div>
              </a>
            </div>
            
          </div>
        </div>

        <div class="my-pagination">
          <nav aria-label="Page navigation example">
            <ul class="pagination">

              <li class="page-item">
                <button class="page-link" @click="get_video(1)">
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>

              <li class="page-item">
                <button class="page-link" :disabled="pagination.current_page == 1" @click="get_video(pagination.current_page - 1)">
                  <span aria-hidden="true">&lt;</span>
                </button>
              </li>

              <li class="page-item" v-if="pagination.current_page > 3">
                <button class="page-link" @click="get_video(pagination.current_page - 3)">{{ pagination.current_page - 3 }}</button>
              </li>

              <li class="page-item" v-if="pagination.current_page > 2">
                <button class="page-link" @click="get_video(pagination.current_page - 2)">{{ pagination.current_page - 2 }}</button>
              </li>

              <li class="page-item" v-if="pagination.current_page > 1">
                <button class="page-link" @click="get_video(pagination.current_page - 1)">{{ pagination.current_page - 1 }}</button>
              </li>
              
              <li class="page-item">
                <button class="page-link active" @click="get_video(pagination.current_page)">{{ pagination.current_page }}</button>
              </li>
              
              <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                <button class="page-link" @click="get_video(pagination.current_page + 1)">{{ pagination.current_page + 1}}</button>
              </li>

              <li class="page-item" v-if="pagination.current_page + 1 < pagination.last_page">
                <button class="page-link" @click="get_video(pagination.current_page + 2)">{{ pagination.current_page + 2}}</button>
              </li>

              <li class="page-item" v-if="pagination.current_page + 2 < pagination.last_page">
                <button class="page-link" @click="get_video(pagination.current_page + 3)">{{ pagination.current_page + 3}}</button>
              </li>

              <li class="page-item">
                <button class="page-link" :disabled="pagination.current_page == pagination.last_page" @click="get_video(pagination.current_page + 1)">
                  <span aria-hidden="true">&gt;</span>
                </button>
              </li>
              
              <li class="page-item">
                <button class="page-link" @click="get_video(pagination.last_page)">
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>

            </ul>
          </nav>
        </div>

      </div>
    </section>
    <myfooter></myfooter>
  </main>
</template>

<script>
import myheader from "@/components/myheader.vue";
import myfooter from "@/components/myfooter.vue";
import i18n from '@/i18n';
import axios from 'axios';

export default {
  name: "videos",
  components: { myheader, myfooter },
  data() {
    return {
        lang: i18n.global.locale,
        api_url: process.env.VUE_APP_API_URL,
        video: new Array,
        pagination: new Object,
    };
  },
  methods: {
    get_video(per_page = 1) {
      axios
        .get(this.api_url + "api/category/videogalereya?lang=" + this.lang + "&page=" + per_page)
        .then((response) => {
          this.video = response.data.data;
          this.pagination = response.data.meta;
          // console.log(response.data.meta);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {},
  mounted() {
    this.get_video();
  },
};
</script>
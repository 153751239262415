import { createRouter, createWebHistory, RouterView } from 'vue-router'
import i18n from '@/i18n'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import news from '../views/news.vue'
import detail from '../views/detail.vue'
import fotos from '../views/fotos.vue'
import videos from '../views/videos.vue'

const routes = [
  {
    path: '/',
    redirect: `${i18n.global.locale}/`,
  },
  {
    path: '/:lang/',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
      },
      {
        path: 'about',
        name: 'about',
        component: AboutView,
      },
      {
        path: 'news',
        name: 'news',
        component: news,
      },
      {
        path: 'detail/:slug',
        name: 'detail',
        component: detail,
      },
      {
        path: 'fotos',
        name: 'fotos',
        component: fotos,
      },
      {
        path: 'videos',
        name: 'videos',
        component: videos,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  i18n.global.locale = to.params.lang;
  next();
})

export default router

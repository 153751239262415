export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uz hello i18n !!"])},
  "play_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видеони кўриш"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Манзил"])},
  "address_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самарқанд вилояти, Нуробод тумани"])},
  "it_village": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>IT Village</span> <span class='heading-first'>нима?</span>"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лойиҳа контенти"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бундан ташқари"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сонлар"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Янгиликлар"])},
  "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фотогалерея"])},
  "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бизнинг ҳамкорлар"])},
  "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='simple'>Нуробод тумани аҳолиси учун</span> <span class='bold'>ИТ соҳаларида бепул тренинг</span>"])},
  "stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иштирок этиш"])},
  "seen_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Харитада кўриш"])},
  "site_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Материаллардан фойдаланишда itvillage.uz ҳаволаси шарт."])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022© itvillage.uz - Барча ҳуқуқлар ҳимояланган"])},
  "ctrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диққат! Агар матнда хато топсангиз, уни танланг ва маъмуриятни хабардор қилиш учун Ctrl+Enter тугмаларини босинг"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ёпиш"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Геожойлашуви"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видеогалерея"])}
}
<template>
  <main class="page">
    <myheader></myheader>
    <section>
      <div class="container">
        <div class="heading">
          <div class="heading-in">
            <span>{{ $t("news") }}</span>
          </div>
        </div>
      </div>
    </section>
    <section class="pb-5">
      <div class="container">
        <div class="row justify-content-center">
          <div
            v-for="item in news"
            :key="item.id"
            class="col-xxl-3 col-xl-4 col-md-6"
          >
            <div class="news">
              <router-link :to="{ name: 'detail', params: { slug: item.slug } }">
                <div class="news-in">
                  <div class="news-img">
                    <img :src="[ item.anons_image == null ? '/img/news-1.jpg' : api_url + item.anons_image ]" />
                  </div>
                  <div class="news-card">
                    <div class="news-title">
                      <span>{{ item.list_title }}</span>
                    </div>
                    <div class="news-description">
                      <span>{{ item.list_description }}</span>
                    </div>
                    <div class="news-date-right">
                      <span class="news-date">{{ item.date }}</span>
                      <img src="/img/news-right.svg" />
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="my-pagination">
          <nav aria-label="Page navigation example">
            <ul class="pagination">

              <li class="page-item">
                <button class="page-link" @click="get_news(1)">
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>

              <li class="page-item">
                <button class="page-link" :disabled="pagination.current_page == 1" @click="get_news(pagination.current_page - 1)">
                  <span aria-hidden="true">&lt;</span>
                </button>
              </li>

              <li class="page-item" v-if="pagination.current_page > 3">
                <button class="page-link" @click="get_news(pagination.current_page - 3)">{{ pagination.current_page - 3 }}</button>
              </li>

              <li class="page-item" v-if="pagination.current_page > 2">
                <button class="page-link" @click="get_news(pagination.current_page - 2)">{{ pagination.current_page - 2 }}</button>
              </li>

              <li class="page-item" v-if="pagination.current_page > 1">
                <button class="page-link" @click="get_news(pagination.current_page - 1)">{{ pagination.current_page - 1 }}</button>
              </li>
              
              <li class="page-item">
                <button class="page-link active" @click="get_news(pagination.current_page)">{{ pagination.current_page }}</button>
              </li>
              
              <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                <button class="page-link" @click="get_news(pagination.current_page + 1)">{{ pagination.current_page + 1}}</button>
              </li>

              <li class="page-item" v-if="pagination.current_page + 1 < pagination.last_page">
                <button class="page-link" @click="get_news(pagination.current_page + 2)">{{ pagination.current_page + 2}}</button>
              </li>

              <li class="page-item" v-if="pagination.current_page + 2 < pagination.last_page">
                <button class="page-link" @click="get_news(pagination.current_page + 3)">{{ pagination.current_page + 3}}</button>
              </li>

              <li class="page-item">
                <button class="page-link" :disabled="pagination.current_page == pagination.last_page" @click="get_news(pagination.current_page + 1)">
                  <span aria-hidden="true">&gt;</span>
                </button>
              </li>
              
              <li class="page-item">
                <button class="page-link" @click="get_news(pagination.last_page)">
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>

            </ul>
          </nav>
        </div>
      </div>
    </section>
    <myfooter></myfooter>
  </main>
</template>

<script>
import axios from "axios";
import myheader from "@/components/myheader.vue";
import myfooter from "@/components/myfooter.vue";
import i18n from "../i18n";

export default {
  name: "news",
  components: {
    myheader,
    myfooter,
    
  },
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
      lang: i18n.global.locale,
      news: new Array(),
      paginate: 12,
      pagination: new Object,
    };
  },
  methods: {
    get_news(per_page = 1) {
      axios
        .get(
          this.api_url +
            "api/category/yangiliklar?lang=" +
            this.lang +
            "&paginate=" +
            this.paginate +
            "&page=" + per_page
        )
        .then((response) => {
          this.news = response.data.data;
          this.pagination = response.data.meta;
          // console.log(this.pagination);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {},
  mounted() {
    this.get_news();
  },
};
</script>

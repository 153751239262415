export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oz hello i18n !!"])},
  "play_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videoni ko’rish"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manzil"])},
  "address_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samarqand viloyati, Nurobod tumani"])},
  "it_village": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>IT Village</span> <span class='heading-first'>nima?</span>"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyiha kontenti"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundan tashqari"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonlar"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yangiliklar"])},
  "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotogalereya"])},
  "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizning hamkorlar"])},
  "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='simple'>Nurobod tumani aholisi uchun</span> <span class='bold'>IT sohalarida bepul trening</span>"])},
  "stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ishtirok etish"])},
  "seen_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xaritada ko’rish"])},
  "site_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materiallardan foydalanishda itvillage.uz havolasi shart."])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022© itvillage.uz - Barcha huquqlar himoyalangan"])},
  "ctrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diqqat! Agar matnda xato topsangiz, uni tanlang va ma'muriyatni xabardor qilish uchun Ctrl + Enter tugmalarini bosing"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yopish"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geojoylashuvi"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videogalereya"])}
}